import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
  },
})
export default class CategoryForm extends mixins(EntityForm) {
  model = {};
  platformLanguages = [];

  get elements() {
    return [
      {
        id: "code",
        label: this.translations.labels.category_form_code,
        type: "text",
        required: true,
        rules: "min:4",
        onAction: this.noSpaces,
      },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations.labels.form_localizations,
        languages: this.languages,
        resetColumns: true,
        elements: [
          {
            id: "name",
            label: this.translations.labels.category_name,
            type: "text",
            required: true,
          },
          {
            id: "description",
            label: this.translations.labels.category_description,
            type: "text",
          },
        ],
      },
    ];
  }

  get languages() {
   return this.platformLanguages;
  }

  get helpLink() {
    return this.translations.pageHelp.category;
  }

  noSpaces(evt) {
    let charCode = evt.charCode;
    if (charCode === 32) evt.preventDefault();
    return true;
  }


  // eslint-disable-next-line no-empty-function
  afterCreate () {
  }

  async created() {
    this.platformLanguages = await this.languageService.list();

    this.afterCreate();
  }
}
